@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 125%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "Libre Franklin", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #444545;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 57.5rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -1rem;
    margin-left: -1rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 1rem;
    padding-left: 1rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .row.gutter-large > .column, .row.gutter-large > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 1rem;
  padding-left: 1rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.75rem;
      padding-left: 0.75rem; } }
  @media print, screen and (min-width: 64em) {
    .column, .columns {
      padding-right: 0.75rem;
      padding-left: 0.75rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1rem;
  padding-left: 1rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    width: 8.33333%; }
  .xlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 16.66667%; }
  .xlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    width: 25%; }
  .xlarge-push-3 {
    position: relative;
    left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    width: 33.33333%; }
  .xlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    width: 41.66667%; }
  .xlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    width: 50%; }
  .xlarge-push-6 {
    position: relative;
    left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    width: 58.33333%; }
  .xlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    width: 66.66667%; }
  .xlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    width: 75%; }
  .xlarge-push-9 {
    position: relative;
    left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    width: 83.33333%; }
  .xlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    width: 91.66667%; }
  .xlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 2rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.5rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .column-block {
      margin-bottom: 1.5rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.4;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-style: normal;
  font-weight: bold;
  color: #333;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #ccc; }

h1 {
  font-size: 1.2rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 0.9rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 0.85rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 0.8rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 1.8rem;
    margin-bottom: 1.25rem; }
  h2 {
    font-size: 1.2rem; }
  h4 {
    font-size: 1rem; }
  h5 {
    font-size: 0.9rem; }
  h6 {
    font-size: 0.8rem; } }

a {
  line-height: inherit;
  color: #022e92;
  text-decoration: underline;
  cursor: pointer; }
  a:hover, a:focus {
    color: #02287e;
    text-decoration: none; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 57.5rem;
  height: 0;
  margin: 1.5rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #ccc;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.4; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.45rem 1rem 0 0.95rem;
  border-left: 1px solid #ccc; }
  blockquote, blockquote p {
    line-height: 1.4;
    color: #737373; }

cite {
  display: block;
  font-size: 0.65rem;
  color: #737373; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #333;
  color: #444545;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.1rem 0.25rem 0.05rem;
  border: 1px solid #ccc;
  background-color: #efefef;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #333; }

kbd {
  margin: 0;
  padding: 0.1rem 0.2rem 0;
  background-color: #efefef;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #333; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.3;
  color: #737373; }

.lead {
  font-size: 156.25%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #737373;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.6rem 1rem;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: none;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #29478d;
  color: #fff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #233c78;
    color: #fff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #29478d;
    color: #fff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #213971;
      color: #fff; }
  .button.secondary {
    background-color: #ab141a;
    color: #fff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #891015;
      color: #fff; }
  .button.success {
    background-color: #3adb76;
    color: #333; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #333; }
  .button.warning {
    background-color: #ffae00;
    color: #333; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #333; }
  .button.alert {
    background-color: #ab141a;
    color: #fff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #891015;
      color: #fff; }
  .button.hollow {
    border: 1px solid #29478d;
    color: #29478d; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #152447;
      color: #152447; }
    .button.hollow.primary {
      border: 1px solid #29478d;
      color: #29478d; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #152447;
        color: #152447; }
    .button.hollow.secondary {
      border: 1px solid #ab141a;
      color: #ab141a; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #560a0d;
        color: #560a0d; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #ab141a;
      color: #ab141a; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #560a0d;
        color: #560a0d; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
      background-color: #29478d;
      color: #fff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #29478d;
        color: #fff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #ab141a;
        color: #fff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #fff; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #fff; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #ab141a;
        color: #fff; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1rem; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 1.95rem;
  margin: 0 0 0.8rem;
  padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 0;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(51, 51, 51, 0.1);
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: normal;
  color: #333;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #737373;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #737373; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #efefef;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 0.8rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.4rem;
  margin-right: 0.8rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.4rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.8;
  color: #404040; }
  label.middle {
    margin: 0 0 0.8rem;
    padding: 0.45rem 0; }

.help-text {
  margin-top: -0.4rem;
  font-size: 0.65rem;
  font-style: italic;
  color: #333; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 0.8rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #ccc;
  background: #efefef;
  color: #333;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.8rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.4rem; }

.fieldset {
  margin: 0.9rem 0;
  padding: 1rem;
  border: 1px solid #ccc; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.15rem;
    padding: 0 0.15rem;
    background: #fff; }

select {
  height: 1.95rem;
  margin: 0 0 0.8rem;
  padding: 0.4rem;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 0;
  background-color: #fff;
  font-family: inherit;
  font-size: 0.8rem;
  line-height: normal;
  color: #333;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28115, 115, 115%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -0.8rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.2rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #737373;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #efefef;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #ab141a;
  background-color: #f7e8e8; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #ab141a; }

.is-invalid-label {
  color: #ab141a; }

.form-error {
  display: none;
  margin-top: -0.4rem;
  margin-bottom: 0.8rem;
  font-size: 0.6rem;
  font-weight: bold;
  color: #ab141a; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #fff;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #efefef;
  border-bottom: 0;
  font-size: 0.6rem;
  line-height: 1;
  color: #29478d; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #efefef;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #efefef; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '–'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #efefef;
  border-bottom: 0;
  background-color: #fff;
  color: #444545; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #efefef; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fff transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.7rem;
    color: #fff;
    cursor: default; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #ccc; }
  .breadcrumbs a {
    color: #fff; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #ccc;
    cursor: not-allowed; }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(51, 51, 51, 0.25);
  border-radius: 0;
  background-color: white;
  color: #fff; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #29478d;
    color: #fff; }
  .callout.secondary {
    background-color: #ab141a;
    color: #fff; }
  .callout.success {
    background-color: #3adb76;
    color: #fff; }
  .callout.warning {
    background-color: #ffae00;
    color: #fff; }
  .callout.alert {
    background-color: #ab141a;
    color: #fff; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.close-button {
  position: absolute;
  color: #737373;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #333; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  @media screen and (min-width: 75em) {
    .menu.xlarge-horizontal > li {
      display: table-cell; }
    .menu.xlarge-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.xlarge-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.xlarge-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    margin-right: 1rem;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #29478d;
    color: #fff; }
  .menu.menu-bordered li {
    border: 1px solid #efefef; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #efefef; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
    content: ''; }
  .menu-icon:hover::after {
    background: #ccc;
    box-shadow: 0 7px 0 #ccc, 0 14px 0 #ccc; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #333;
    box-shadow: 0 7px 0 #333, 0 14px 0 #333;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #737373;
    box-shadow: 0 7px 0 #737373, 0 14px 0 #737373; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; } }

@media screen and (min-width: 75em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #ccc;
  background: #fff; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed, .flex-video {
  position: relative;
  height: 0;
  margin-bottom: 0.8rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video, .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen, .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #29478d;
  color: #fff; }
  .label.primary {
    background: #29478d;
    color: #fff; }
  .label.secondary {
    background: #ab141a;
    color: #fff; }
  .label.success {
    background: #3adb76;
    color: #333; }
  .label.warning {
    background: #ffae00;
    color: #333; }
  .label.alert {
    background: #ab141a;
    color: #fff; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  color: #fff; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fff; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(51, 51, 51, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #ccc; }
    .orbit-bullets button:hover {
      background-color: #737373; }
    .orbit-bullets button.is-active {
      background-color: #737373; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f2f2f2;
    background-color: #fff; }
  caption {
    padding: 0.4rem 0.5rem 0.5rem;
    font-weight: bold; }
  thead {
    background: #29478d;
    color: #fff; }
  tfoot {
    background: #ccc;
    color: #444545; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.4rem 0.5rem 0.5rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.4rem 0.5rem 0.5rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #fff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fff; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #274385; }

table.hover tfoot tr:hover {
  background-color: #c7c7c7; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(51, 51, 51, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(41, 71, 141, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #333;
  color: #fff; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: transparent; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 64em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 89.9375em) {
    .top-bar.stacked-for-xlarge .top-bar-left,
    .top-bar.stacked-for-xlarge .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (max-width: 39.9375em) {
  body {
    font-size: 0.8rem; } }

address {
  font-style: normal; }

h2 a, h3 a, h4 a {
  text-decoration: none; }
  h2 a:hover, h2 a:focus, h3 a:hover, h3 a:focus, h4 a:hover, h4 a:focus {
    text-decoration: underline; }

.h1, .h2 {
  font-size: 0.9rem;
  text-transform: none;
  color: #333;
  line-height: 1.4;
  font-family: Georgia, Times, "Times New Roman", serif; }
  @media print, screen and (min-width: 40em) {
    .h1, .h2 {
      font-size: 1.2rem; } }

h3, .h3 {
  font-family: "Libre Franklin", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  color: #737373;
  font-size: 1.1rem;
  font-weight: bold; }

.h5 {
  font-size: 0.9rem; }

blockquote {
  border: 0;
  position: relative;
  padding-left: 40px;
  margin-bottom: 1.5rem;
  display: inline-block; }
  blockquote p {
    font-family: Georgia, Times, "Times New Roman", serif;
    color: #444545;
    font-weight: bold; }
    blockquote p:last-child {
      margin-bottom: 0; }
      blockquote p:last-child:after {
        font-weight: normal;
        right: 0;
        bottom: 25px;
        content: "\201d"; }
  blockquote:before {
    left: 0;
    top: -10px;
    content: "\201c"; }
  blockquote:before, blockquote p:last-child:after {
    position: absolute;
    height: 0;
    color: #ab141a;
    font-size: 3.5rem;
    font-family: Georgia, Times, "Times New Roman", serif; }
  blockquote p.blockquote-attribution {
    font-family: "Libre Franklin", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: normal;
    margin-bottom: 0;
    position: relative; }
    blockquote p.blockquote-attribution:before {
      content: "\2014";
      margin-right: 3px; }
    blockquote p.blockquote-attribution:last-child:after {
      top: -45px; }

table {
  font-size: 0.9rem; }

ul:not([class]) li, ol:not([class]) li {
  margin-bottom: 0.5rem; }
  ul:not([class]) li ul, ul:not([class]) li ol, ol:not([class]) li ul, ol:not([class]) li ol {
    margin-top: 0.5rem; }

.h-100 {
  height: 100% !important; }

.w-100 {
  width: 100% !important; }

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.m-a-0 {
  margin: 0 0 !important; }
  @media screen and (min-width: 64em) {
    .m-a-0 {
      margin: 0 0 !important; } }

.m-t-0 {
  margin-top: 0 !important; }
  @media screen and (min-width: 64em) {
    .m-t-0 {
      margin-top: 0 !important; } }

.m-r-0 {
  margin-right: 0 !important; }
  @media screen and (min-width: 40em) {
    .m-r-0 {
      margin-right: 0 !important; } }

@media screen and (max-width: 39.9375em) {
  .small-m-b-0 {
    margin-bottom: 0 !important; } }

.m-b-0 {
  margin-bottom: 0 !important; }
  @media screen and (min-width: 40em) {
    .m-b-0 {
      margin-bottom: 0 !important; } }

.m-l-0 {
  margin-left: 0 !important; }
  @media screen and (min-width: 40em) {
    .m-l-0 {
      margin-left: 0 !important; } }

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }
  @media print, screen and (min-width: 64em) {
    .m-x-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; } }

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  @media print, screen and (min-width: 64em) {
    .m-y-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; } }

.m-a-1 {
  margin: 0.25rem 0.25rem !important; }
  @media screen and (min-width: 64em) {
    .m-a-1 {
      margin: 0.5rem 0.5rem !important; } }

.m-t-1 {
  margin-top: 0.25rem !important; }
  @media screen and (min-width: 64em) {
    .m-t-1 {
      margin-top: 0.5rem !important; } }

.m-r-1 {
  margin-right: 0.25rem !important; }
  @media screen and (min-width: 40em) {
    .m-r-1 {
      margin-right: 0.5rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-m-b-1 {
    margin-bottom: 0.5rem !important; } }

.m-b-1 {
  margin-bottom: 0.25rem !important; }
  @media screen and (min-width: 40em) {
    .m-b-1 {
      margin-bottom: 0.5rem !important; } }

.m-l-1 {
  margin-left: 0.25rem !important; }
  @media screen and (min-width: 40em) {
    .m-l-1 {
      margin-left: 0.5rem !important; } }

.m-x-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-x-1 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; } }

.m-y-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-y-1 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; } }

.m-a-2 {
  margin: 0.375rem 0.375rem !important; }
  @media screen and (min-width: 64em) {
    .m-a-2 {
      margin: 0.75rem 0.75rem !important; } }

.m-t-2 {
  margin-top: 0.375rem !important; }
  @media screen and (min-width: 64em) {
    .m-t-2 {
      margin-top: 0.75rem !important; } }

.m-r-2 {
  margin-right: 0.375rem !important; }
  @media screen and (min-width: 40em) {
    .m-r-2 {
      margin-right: 0.75rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-m-b-2 {
    margin-bottom: 0.75rem !important; } }

.m-b-2 {
  margin-bottom: 0.375rem !important; }
  @media screen and (min-width: 40em) {
    .m-b-2 {
      margin-bottom: 0.75rem !important; } }

.m-l-2 {
  margin-left: 0.375rem !important; }
  @media screen and (min-width: 40em) {
    .m-l-2 {
      margin-left: 0.75rem !important; } }

.m-x-2 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-x-2 {
      margin-right: 0.75rem !important;
      margin-left: 0.75rem !important; } }

.m-y-2 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-y-2 {
      margin-top: 0.75rem !important;
      margin-bottom: 0.75rem !important; } }

.m-a-3 {
  margin: 0.75rem 0.75rem !important; }
  @media screen and (min-width: 64em) {
    .m-a-3 {
      margin: 1.5rem 1.5rem !important; } }

.m-t-3 {
  margin-top: 0.75rem !important; }
  @media screen and (min-width: 64em) {
    .m-t-3 {
      margin-top: 1.5rem !important; } }

.m-r-3 {
  margin-right: 0.75rem !important; }
  @media screen and (min-width: 40em) {
    .m-r-3 {
      margin-right: 1.5rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-m-b-3 {
    margin-bottom: 1.5rem !important; } }

.m-b-3 {
  margin-bottom: 0.75rem !important; }
  @media screen and (min-width: 40em) {
    .m-b-3 {
      margin-bottom: 1.5rem !important; } }

.m-l-3 {
  margin-left: 0.75rem !important; }
  @media screen and (min-width: 40em) {
    .m-l-3 {
      margin-left: 1.5rem !important; } }

.m-x-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-x-3 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; } }

.m-y-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-y-3 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; } }

.m-a-4 {
  margin: 1rem 1rem !important; }
  @media screen and (min-width: 64em) {
    .m-a-4 {
      margin: 2rem 2rem !important; } }

.m-t-4 {
  margin-top: 1rem !important; }
  @media screen and (min-width: 64em) {
    .m-t-4 {
      margin-top: 2rem !important; } }

.m-r-4 {
  margin-right: 1rem !important; }
  @media screen and (min-width: 40em) {
    .m-r-4 {
      margin-right: 2rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-m-b-4 {
    margin-bottom: 2rem !important; } }

.m-b-4 {
  margin-bottom: 1rem !important; }
  @media screen and (min-width: 40em) {
    .m-b-4 {
      margin-bottom: 2rem !important; } }

.m-l-4 {
  margin-left: 1rem !important; }
  @media screen and (min-width: 40em) {
    .m-l-4 {
      margin-left: 2rem !important; } }

.m-x-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-x-4 {
      margin-right: 2rem !important;
      margin-left: 2rem !important; } }

.m-y-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-y-4 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important; } }

.m-a-5 {
  margin: 1.25rem 1.25rem !important; }
  @media screen and (min-width: 64em) {
    .m-a-5 {
      margin: 2.5rem 2.5rem !important; } }

.m-t-5 {
  margin-top: 1.25rem !important; }
  @media screen and (min-width: 64em) {
    .m-t-5 {
      margin-top: 2.5rem !important; } }

.m-r-5 {
  margin-right: 1.25rem !important; }
  @media screen and (min-width: 40em) {
    .m-r-5 {
      margin-right: 2.5rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-m-b-5 {
    margin-bottom: 2.5rem !important; } }

.m-b-5 {
  margin-bottom: 1.25rem !important; }
  @media screen and (min-width: 40em) {
    .m-b-5 {
      margin-bottom: 2.5rem !important; } }

.m-l-5 {
  margin-left: 1.25rem !important; }
  @media screen and (min-width: 40em) {
    .m-l-5 {
      margin-left: 2.5rem !important; } }

.m-x-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-x-5 {
      margin-right: 2.5rem !important;
      margin-left: 2.5rem !important; } }

.m-y-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important; }
  @media print, screen and (min-width: 64em) {
    .m-y-5 {
      margin-top: 2.5rem !important;
      margin-bottom: 2.5rem !important; } }

.p-a-0 {
  padding: 0 0 !important; }
  @media screen and (min-width: 64em) {
    .p-a-0 {
      padding: 0 0 !important; } }

.p-t-0 {
  padding-top: 0 !important; }
  @media screen and (min-width: 64em) {
    .p-t-0 {
      padding-top: 0 !important; } }

.p-r-0 {
  padding-right: 0 !important; }
  @media screen and (min-width: 40em) {
    .p-r-0 {
      padding-right: 0 !important; } }

@media screen and (max-width: 39.9375em) {
  .small-p-b-0 {
    padding-bottom: 0 !important; } }

.p-b-0 {
  padding-bottom: 0 !important; }
  @media screen and (min-width: 40em) {
    .p-b-0 {
      padding-bottom: 0 !important; } }

.p-l-0 {
  padding-left: 0 !important; }
  @media screen and (min-width: 40em) {
    .p-l-0 {
      padding-left: 0 !important; } }

.p-x-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }
  @media print, screen and (min-width: 64em) {
    .p-x-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; } }

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }
  @media print, screen and (min-width: 64em) {
    .p-y-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; } }

.p-a-1 {
  padding: 0.25rem 0.25rem !important; }
  @media screen and (min-width: 64em) {
    .p-a-1 {
      padding: 0.5rem 0.5rem !important; } }

.p-t-1 {
  padding-top: 0.25rem !important; }
  @media screen and (min-width: 64em) {
    .p-t-1 {
      padding-top: 0.5rem !important; } }

.p-r-1 {
  padding-right: 0.25rem !important; }
  @media screen and (min-width: 40em) {
    .p-r-1 {
      padding-right: 0.5rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-p-b-1 {
    padding-bottom: 0.5rem !important; } }

.p-b-1 {
  padding-bottom: 0.25rem !important; }
  @media screen and (min-width: 40em) {
    .p-b-1 {
      padding-bottom: 0.5rem !important; } }

.p-l-1 {
  padding-left: 0.25rem !important; }
  @media screen and (min-width: 40em) {
    .p-l-1 {
      padding-left: 0.5rem !important; } }

.p-x-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-x-1 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; } }

.p-y-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-y-1 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; } }

.p-a-2 {
  padding: 0.375rem 0.375rem !important; }
  @media screen and (min-width: 64em) {
    .p-a-2 {
      padding: 0.75rem 0.75rem !important; } }

.p-t-2 {
  padding-top: 0.375rem !important; }
  @media screen and (min-width: 64em) {
    .p-t-2 {
      padding-top: 0.75rem !important; } }

.p-r-2 {
  padding-right: 0.375rem !important; }
  @media screen and (min-width: 40em) {
    .p-r-2 {
      padding-right: 0.75rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-p-b-2 {
    padding-bottom: 0.75rem !important; } }

.p-b-2 {
  padding-bottom: 0.375rem !important; }
  @media screen and (min-width: 40em) {
    .p-b-2 {
      padding-bottom: 0.75rem !important; } }

.p-l-2 {
  padding-left: 0.375rem !important; }
  @media screen and (min-width: 40em) {
    .p-l-2 {
      padding-left: 0.75rem !important; } }

.p-x-2 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-x-2 {
      padding-right: 0.75rem !important;
      padding-left: 0.75rem !important; } }

.p-y-2 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-y-2 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important; } }

.p-a-3 {
  padding: 0.75rem 0.75rem !important; }
  @media screen and (min-width: 64em) {
    .p-a-3 {
      padding: 1.5rem 1.5rem !important; } }

.p-t-3 {
  padding-top: 0.75rem !important; }
  @media screen and (min-width: 64em) {
    .p-t-3 {
      padding-top: 1.5rem !important; } }

.p-r-3 {
  padding-right: 0.75rem !important; }
  @media screen and (min-width: 40em) {
    .p-r-3 {
      padding-right: 1.5rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-p-b-3 {
    padding-bottom: 1.5rem !important; } }

.p-b-3 {
  padding-bottom: 0.75rem !important; }
  @media screen and (min-width: 40em) {
    .p-b-3 {
      padding-bottom: 1.5rem !important; } }

.p-l-3 {
  padding-left: 0.75rem !important; }
  @media screen and (min-width: 40em) {
    .p-l-3 {
      padding-left: 1.5rem !important; } }

.p-x-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-x-3 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; } }

.p-y-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-y-3 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; } }

.p-a-4 {
  padding: 1rem 1rem !important; }
  @media screen and (min-width: 64em) {
    .p-a-4 {
      padding: 2rem 2rem !important; } }

.p-t-4 {
  padding-top: 1rem !important; }
  @media screen and (min-width: 64em) {
    .p-t-4 {
      padding-top: 2rem !important; } }

.p-r-4 {
  padding-right: 1rem !important; }
  @media screen and (min-width: 40em) {
    .p-r-4 {
      padding-right: 2rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-p-b-4 {
    padding-bottom: 2rem !important; } }

.p-b-4 {
  padding-bottom: 1rem !important; }
  @media screen and (min-width: 40em) {
    .p-b-4 {
      padding-bottom: 2rem !important; } }

.p-l-4 {
  padding-left: 1rem !important; }
  @media screen and (min-width: 40em) {
    .p-l-4 {
      padding-left: 2rem !important; } }

.p-x-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-x-4 {
      padding-right: 2rem !important;
      padding-left: 2rem !important; } }

.p-y-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-y-4 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important; } }

.p-a-5 {
  padding: 1.25rem 1.25rem !important; }
  @media screen and (min-width: 64em) {
    .p-a-5 {
      padding: 2.5rem 2.5rem !important; } }

.p-t-5 {
  padding-top: 1.25rem !important; }
  @media screen and (min-width: 64em) {
    .p-t-5 {
      padding-top: 2.5rem !important; } }

.p-r-5 {
  padding-right: 1.25rem !important; }
  @media screen and (min-width: 40em) {
    .p-r-5 {
      padding-right: 2.5rem !important; } }

@media screen and (max-width: 39.9375em) {
  .small-p-b-5 {
    padding-bottom: 2.5rem !important; } }

.p-b-5 {
  padding-bottom: 1.25rem !important; }
  @media screen and (min-width: 40em) {
    .p-b-5 {
      padding-bottom: 2.5rem !important; } }

.p-l-5 {
  padding-left: 1.25rem !important; }
  @media screen and (min-width: 40em) {
    .p-l-5 {
      padding-left: 2.5rem !important; } }

.p-x-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-x-5 {
      padding-right: 2.5rem !important;
      padding-left: 2.5rem !important; } }

.p-y-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important; }
  @media print, screen and (min-width: 64em) {
    .p-y-5 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important; } }

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 30; }

.sr-only {
  position: absolute !important;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.flex-container {
  display: flex; }

.objectFit-cover {
  object-fit: cover; }

img {
  height: auto !important; }

.img--left {
  float: left;
  margin: 0 30px 30px 0;
  max-width: 40%; }

.img--right {
  float: right;
  margin: 0 30px 30px;
  max-width: 40%; }

.clear {
  clear: both; }

.font-small {
  font-size: 0.7rem; }

.textTransform-none {
  text-transform: none; }

.l-utility {
  overflow: hidden;
  position: relative; }
  .l-utility .button {
    border: 3px solid #fff;
    font-size: 0.8rem; }
    @media screen and (max-width: 39.9375em) {
      .l-utility .button {
        font-size: 0.7rem;
        padding: 11px 16px; } }
    .l-utility .button:not(.hollow):hover, .l-utility .button:not(.hollow):focus {
      border-color: #ab141a; }
  @media screen and (max-width: 63.9375em) {
    .l-utility .l-utility-list {
      overflow: hidden;
      display: block; }
      .l-utility .l-utility-list > li {
        width: 33.33%;
        margin-left: 0 !important;
        float: left;
        display: block;
        text-align: center; }
        .l-utility .l-utility-list > li:first-child {
          text-align: left; }
        .l-utility .l-utility-list > li:last-child {
          text-align: right; }
      .l-utility .l-utility-list .status-wrapper {
        padding-top: 10px; } }
  @media print, screen and (min-width: 64em) {
    .l-utility {
      margin-bottom: -50px; } }

.l-top {
  background-repeat: no-repeat;
  background-size: auto 500px;
  background-color: #737373;
  background-position: 40% bottom;
  padding: 17px 0;
  position: relative;
  color: #fff; }
  .l-top-social {
    width: 39%;
    margin-top: 35px;
    padding-left: 12px; }
    @media print, screen and (min-width: 40em) {
      .l-top-social {
        margin-top: 30px; } }
  @media screen and (max-width: 39.9375em) {
    .l-top {
      background-size: auto 300px; }
      .l-top .iconSocial {
        width: 30px;
        padding: 7px 0px;
        height: 30px; } }
  @media print, screen and (min-width: 64em) {
    .l-top {
      background-position: center top;
      background-size: cover; } }
  .l-top-alert {
    padding: 10px; }
    .l-top-alert .readMore {
      font-family: "Libre Franklin", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-weight: normal;
      color: inherit; }
      .l-top-alert .readMore:after {
        border-left-color: #fff; }
  .l-top .hero {
    margin-top: 100px; }
  .l-top .readMore:before {
    background: #fff; }
  .l-top .readMore:after {
    border-left-color: #fff; }
  .l-top > * {
    z-index: 3;
    position: relative; }
  .l-top:after {
    left: 0;
    width: 100%;
    height: 200px; }
    @media screen and (max-width: 63.9375em) {
      .l-top:after {
        z-index: 1;
        content: "";
        background: linear-gradient(to bottom, #737373 0%, transparent 70%, transparent 100%);
        position: absolute;
        bottom: 100px; } }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .l-top:after {
        bottom: 300px; } }
  .l-top:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #032f92;
    opacity: 0.7;
    z-index: 2; }
  .l-top--large--gray:before {
    background-color: #474747; }
  @media print, screen and (min-width: 40em) {
    .l-top--large {
      height: 500px; }
      .l-top--large:before {
        background-color: transparent;
        opacity: 0.8;
        background-image: -o-linear-gradient(180deg, #3054a6 35%, transparent);
        background-image: -moz-linear-gradient(180deg, #3054a6 35%, transparent);
        background-image: -webkit-linear-gradient(180deg, #3054a6 35%, transparent);
        background-image: linear-gradient(180deg, #3054a6 35%, transparent); }
      .l-top--large--gray .iconSocial--light svg g, .l-top--large--gray .iconSocial--light svg path {
        fill: #474747; }
      .l-top--large--gray:before {
        background-image: -o-linear-gradient(180deg, #474747 35%, transparent);
        background-image: -moz-linear-gradient(180deg, #474747 35%, transparent);
        background-image: -webkit-linear-gradient(180deg, #474747 35%, transparent);
        background-image: linear-gradient(180deg, #474747 35%, transparent); } }
  @media screen and (min-width: 75em) {
    .l-top--large {
      height: 600px; } }
  .l-top a:not(.button) {
    color: #fff; }

.l-breadcrumbs {
  position: relative; }

.l-header {
  position: relative; }
  .l-header-title {
    font-size: 0.8rem;
    padding: 0 15px;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-weight: bold; }
    @media print, screen and (min-width: 64em) {
      .l-header-title {
        padding: 0;
        margin-top: 50px;
        font-size: 1.2rem;
        margin-left: 20px;
        display: block; } }
    @media screen and (min-width: 75em) {
      .l-header-title {
        margin-left: 30px;
        margin-bottom: 20px; } }
    .l-header-title a {
      text-decoration: none; }
      .l-header-title a:hover, .l-header-title a:focus {
        text-decoration: none; }
  .l-header-logo {
    display: inline-block;
    float: left;
    margin-right: 10px;
    margin-bottom: 15px;
    text-align: center; }
    @media all and (min-width: 1024px) and (max-width: 1100px) {
      .l-header-logo {
        width: 8%;
        margin-top: 40px;
        margin-bottom: 0; } }
  @media screen and (max-width: 63.9375em) {
    .l-header-logo {
      width: 26%; }
      .l-header-logo img {
        max-width: 100px; } }
  @media screen and (min-width: 75em) {
    .l-header-nav {
      margin-left: 10px; } }

.l-footer {
  background: #333;
  color: #fff;
  padding-top: 10px; }
  @media screen and (max-width: 39.9375em) {
    .l-footer .iconSocial {
      margin-bottom: 0.5rem; } }
  @media screen and (max-width: 63.9375em) {
    .l-footer .menu > li > a {
      padding-left: 0;
      padding-right: 0; } }
  .l-footer-contact {
    margin: 0 0 20px; }
    @media screen and (max-width: 39.9375em) {
      .l-footer-contact {
        margin-top: 0.75rem; } }
  @media print, screen and (min-width: 40em) {
    .l-footer-contact {
      margin: 5px 0 0; } }
  .l-footer hr {
    border-color: #666; }
  .l-footer-links {
    font-size: 0.9rem; }
    .l-footer-links li {
      margin-bottom: 5px; }
  .l-footer-address {
    font-size: 0.9rem;
    line-height: 1.3; }
  .l-footer-title {
    font-size: 1rem;
    color: inherit;
    margin-bottom: 0.25rem; }
  .l-footer-logo {
    max-width: 100px;
    display: inline-block; }
    .l-footer-logo img {
      max-width: 100%; }
  .l-footer a:not(.button) {
    text-decoration: none;
    color: inherit; }
    .l-footer a:not(.button):hover, .l-footer a:not(.button):focus {
      text-decoration: underline; }
  .l-footer .iconSocial svg path, .l-footer .iconSocial svg g {
    fill: #333; }

@media screen and (max-width: 39.9375em) {
  .l-sidebar .module:first-child {
    margin-top: 30px;
    border-top: 2px solid #ccc;
    padding-top: 30px; } }

.l-siteContent {
  padding: 1.5rem 0 0; }
  .l-siteContent > *:last-child {
    margin-bottom: 3rem; }
  @media print, screen and (min-width: 64em) {
    .l-siteContent {
      min-height: 40vh; } }
  @media screen and (min-width: 640px) and (max-width: 1200px) {
    .l-siteContent .row .columns:first-child {
      padding-left: 1.25rem; }
    .l-siteContent .row .columns:last-child {
      padding-right: 1.25rem; } }

@media screen and (max-width: 39.9375em) {
  .l-sidebar .module:first-child {
    border-top: 2px solid #ccc;
    padding-top: 1rem;
    margin-top: 1rem; } }

.section {
  padding: 1.5rem 0; }
  @media print, screen and (min-width: 64em) {
    .section {
      padding: 3rem 0; } }
  .section-label {
    font-size: 1.5rem;
    margin-bottom: 1.25rem; }
  .section--highlight {
    background: #29478d;
    color: #fff;
    padding: 2.5rem 0; }
    .section--highlight .section-header {
      color: inherit; }
  .section--secondary p:last-child {
    margin-bottom: 0; }
  .section--secondary:last-child {
    margin-top: 1.5rem; }

.hero {
  position: relative; }
  @media screen and (max-width: 63.9375em) {
    .hero-header, .hero-subheader {
      margin-bottom: 15px;
      width: 100%; }
      .hero-header a, .hero-subheader a {
        display: block;
        padding-bottom: 5px;
        width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .hero-columns {
      min-height: 30vh; }
    .hero .readMore--special:before {
      height: 3px; }
    .hero .readMore {
      padding-bottom: 1rem; }
    .hero-header {
      font-size: 2.8rem;
      margin-right: 30px; }
      .hero-header .readMore--special:after {
        border-width: 14px 14px;
        margin-left: 20px; }
    .hero-subheader {
      font-size: 1.4rem; }
      .hero-subheader:not(:last-child) {
        margin-bottom: 3rem; }
      .hero-subheader .readMore--special:after {
        border-width: 9px 8px;
        margin-left: 10px; }
    .hero-sidebar {
      padding-top: 50px; } }

#main-menu > ul > li.is-selected > a {
  text-decoration: underline; }
  @media print, screen and (min-width: 64em) {
    #main-menu > ul > li.is-selected > a {
      text-decoration: none; }
      #main-menu > ul > li.is-selected > a:before {
        opacity: 1;
        transform: translateY(0px); } }

#main-menu > ul > li > a {
  font-weight: bold;
  text-decoration: none;
  position: relative; }
  #main-menu > ul > li > a:before {
    position: absolute;
    bottom: 5px;
    left: 20px;
    background: #fff;
    height: 2px;
    width: calc(100% - 40px);
    content: "";
    opacity: 0;
    transition: 0.2s all;
    transform: translateY(-10px); }
  @media print, screen and (min-width: 64em) {
    #main-menu > ul > li > a:hover, #main-menu > ul > li > a:focus {
      text-decoration: none; }
      #main-menu > ul > li > a:hover:before, #main-menu > ul > li > a:focus:before {
        transform: translateY(0px);
        opacity: 1; } }

#main-menu .submenu {
  margin-bottom: 0.75rem; }
  #main-menu .submenu a {
    font-size: 0.9rem;
    text-decoration: none; }
    #main-menu .submenu a:hover, #main-menu .submenu a:focus {
      text-decoration: underline; }

.top-bar .title-bar {
  background: transparent; }
  .top-bar .title-bar .menu-icon {
    margin-top: 25px; }

.calloutImage {
  display: block;
  position: relative;
  overflow: hidden;
  background: #333; }
  .calloutImage-image {
    margin-bottom: 0;
    transition: 0.3s transform, 0.3s opacity;
    transform: scale(1.006);
    width: 100%; }
  .calloutImage-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 25px 30px;
    background: linear-gradient(to top, rgba(51, 51, 51, 0.8) 0%, transparent 70%, transparent 100%); }
    @media print, screen and (min-width: 64em) {
      .calloutImage-overlay {
        padding: 40px 50px; } }
    .calloutImage-overlay > *:last-child {
      margin-bottom: 0; }
  .calloutImage-header {
    color: #fff;
    line-height: 1.3;
    max-width: 300px;
    text-transform: none; }
    @media print, screen and (min-width: 64em) {
      .calloutImage-header {
        font-size: 1.2rem; } }
    .calloutImage-header.readMore:after {
      border-left-color: #fff; }
  .calloutImage:hover .readMore, .calloutImage:focus .readMore {
    text-decoration: none; }
  .calloutImage:hover .calloutImage-image, .calloutImage:focus .calloutImage-image {
    opacity: 0.7;
    transform: scale(1.1); }

.promo {
  position: relative;
  overflow: hidden;
  z-index: 0; }
  .promo .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .promo-overlay {
    padding: 2rem 1.5rem;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: -o-linear-gradient(to bottom, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.2) 50%, rgba(51, 51, 51, 0.8) 100%);
    background-image: -moz-linear-gradient(to bottom, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.2) 50%, rgba(51, 51, 51, 0.8) 100%);
    background-image: -webkit-linear-gradient(to bottom, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.2) 50%, rgba(51, 51, 51, 0.8) 100%);
    background-image: linear-gradient(to bottom, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.2) 50%, rgba(51, 51, 51, 0.8) 100%);
    position: relative; }
  .promo-button {
    margin-bottom: 0; }
  .promo-header {
    color: #fff; }
  @media print, screen and (min-width: 40em) {
    .promo-overlay {
      padding: 3rem 1.5rem; } }
  @media print, screen and (min-width: 64em) {
    .promo-header {
      font-size: 1.8rem;
      line-height: 1.2;
      margin-bottom: 1.25rem; }
    .promo-overlay {
      padding: 4.5rem 1.5rem; } }
  @media screen and (min-width: 90em) {
    .promo-overlay {
      padding: 7.5rem 0; } }

.modular {
  margin-top: 1.5rem; }
  .modular-item--wysiwyg p:last-child {
    margin-bottom: 0; }

.list--primary {
  font-size: 0.9rem;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-weight: bold; }
  .list--primary > li {
    margin-bottom: 0.4rem; }
  .list--primary a {
    text-decoration: none; }
    .list--primary a:hover, .list--primary a:focus {
      text-decoration: underline; }

.list--subnav {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-left: 0;
  font-size: 0.8rem; }
  .list--subnav-parent.selected > a {
    color: #737373; }
  .list--subnav-parent a {
    font-family: Georgia, Times, "Times New Roman", serif;
    text-transform: none;
    text-decoration: none; }
    .list--subnav-parent a:hover, .list--subnav-parent a:focus {
      text-decoration: underline; }
  .list--subnav li.selected > a {
    color: #737373; }
  .list--subnav a {
    text-decoration: none; }
    .list--subnav a:hover, .list--subnav a:focus {
      text-decoration: underline; }
  .list--subnav > li {
    margin-bottom: 0.4rem; }
    .list--subnav > li ul {
      margin-top: 0.5rem;
      font-size: 0.7rem;
      margin-left: 1.25rem;
      margin-bottom: 0.5rem; }
      .list--subnav > li ul li {
        margin-bottom: 0.5rem; }

.list--secondary {
  font-size: 0.8rem;
  padding-left: 40px;
  color: #777; }
  .list--secondary .columns {
    padding: 0;
    padding-right: 30px;
    margin-bottom: 10px; }

.list--inline {
  display: inline-block;
  list-style-type: none;
  margin-left: 0; }
  .list--inline > li {
    vertical-align: middle;
    display: inline-block; }
    .list--inline > li:not(:first-child) {
      margin-left: 10px; }

.list--unstyled {
  list-style-type: none;
  margin-left: 0; }
  .list--unstyled ul {
    list-style-type: none; }

.iconSocial {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 100%;
  padding: 9px;
  background: #29478d; }
  .iconSocial svg {
    height: 100%;
    width: 100%; }
    .iconSocial svg path, .iconSocial svg g {
      fill: #fff; }
  .iconSocial:hover, .iconSocial:focus {
    background: #233c78; }
  .iconSocial--light {
    background: #fff;
    color: #444545; }
    .iconSocial--light:hover, .iconSocial--light:focus {
      background: #ccc; }
    .iconSocial--light svg path, .iconSocial--light svg g {
      fill: #29478d; }

.icon--play:before {
  content: "";
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  border-width: 10px 10px;
  display: inline-block; }

@media screen and (max-width: 63.9375em) {
  .cardFeature .columns {
    padding: 0; } }

@media print, screen and (min-width: 64em) {
  .cardFeature .columns:not(:first-child) {
    padding-left: 0; }
  .cardFeature .columns:not(:last-child) {
    padding-right: 0; } }

.cardFeature-label {
  font-family: "Libre Franklin", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  margin-bottom: 1.25rem;
  color: inherit;
  font-size: 1.1rem;
  font-weight: bold; }

.cardFeature .readMore {
  font-weight: normal; }
  .cardFeature .readMore:before {
    background: #fff; }
  .cardFeature .readMore:after {
    border-left-color: #fff; }

.cardFeature-content {
  padding: 40px;
  background: #29478d;
  color: #fff; }

.cardFeature-title {
  color: inherit;
  font-size: 1.2rem; }
  @media print, screen and (min-width: 64em) {
    .cardFeature-title {
      font-size: 1.5rem; } }

.cardFeature a {
  color: inherit; }

.cardCallout {
  display: block;
  position: relative;
  color: #fff;
  background: #737373;
  overflow: hidden;
  transition: 0.4s padding; }
  .cardCallout-image {
    width: 100%;
    object-fit: cover;
    object-position: center center;
    max-height: 300px; }
  .cardCallout-label {
    font-weight: bold;
    color: inherit;
    position: relative;
    display: block;
    margin-bottom: 10px; }
    .cardCallout-label-icon {
      margin-right: 2px; }
      .cardCallout-label-icon:before {
        vertical-align: middle;
        margin-top: -5px;
        display: inline-block; }
  .cardCallout-title {
    color: inherit;
    position: relative;
    text-transform: none;
    font-family: Georgia, Times, "Times New Roman", serif;
    transition: 0.4s padding;
    font-size: 1.2rem; }
  .cardCallout:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#333333+69,333333+99&0+25,0.65+60 */
    background: -moz-linear-gradient(top, rgba(51, 51, 51, 0) 25%, rgba(51, 51, 51, 0.65) 60%, rgba(51, 51, 51, 0.65) 69%, rgba(51, 51, 51, 0.65) 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(51, 51, 51, 0) 25%, rgba(51, 51, 51, 0.65) 60%, rgba(51, 51, 51, 0.65) 69%, rgba(51, 51, 51, 0.65) 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(51, 51, 51, 0) 25%, rgba(51, 51, 51, 0.65) 60%, rgba(51, 51, 51, 0.65) 69%, rgba(51, 51, 51, 0.65) 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00333333', endColorstr='#a6333333',GradientType=0 );
    /* IE6-9 */ }
  .cardCallout-overlay {
    padding: 25px 30px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0; }
    .cardCallout-overlay:after {
      height: 0;
      transition: 0.4s all;
      display: block;
      width: 100%;
      background: #fff;
      content: ""; }
  .cardCallout:hover, .cardCallout:focus {
    color: #fff; }
    .cardCallout:hover .cardCallout-overlay:after, .cardCallout:focus .cardCallout-overlay:after {
      height: 2px;
      margin-top: 20px; }
  .cardCallout-overlay {
    position: absolute;
    bottom: 0; }

.card {
  padding: 30px 25px; }
  .card-title {
    margin-bottom: 1rem; }
  @media print, screen and (min-width: 64em) {
    .card {
      padding: 45px 40px; } }
  .card--secondary {
    background: #efefef; }

.module {
  padding-top: 30px; }
  .module-header {
    margin-bottom: 0.5rem; }
  .module:first-child {
    padding-top: 0; }
  .module:not(:last-child) {
    border-bottom: 2px solid #ccc;
    padding-bottom: 30px; }
  .module > *:last-child {
    margin-bottom: 0; }
    .module > *:last-child .button {
      margin-bottom: 0; }

.breadcrumbs a {
  text-decoration: underline; }
  .breadcrumbs a:hover, .breadcrumbs a:focus {
    text-decoration: none; }

.breadcrumbs li:last-child a {
  color: inherit;
  text-decoration: none; }

.breadcrumbs li:not(:last-child):after {
  content: "";
  border-style: solid;
  height: 0;
  border-width: 5px 4px;
  width: 0;
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px 0px 10px;
  top: 0;
  border-color: transparent transparent transparent #fff; }

.lity {
  z-index: 9990;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  background: #0b0b0b;
  background: rgba(0, 0, 0, 0.9);
  outline: none !important;
  opacity: 0;
  transition: opacity 0.3s ease; }
  .lity.lity-opened {
    opacity: 1; }
  .lity.lity-closed {
    opacity: 0; }
  .lity * {
    box-sizing: border-box; }

.lity-wrap {
  z-index: 9990;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  outline: none !important; }
  .lity-wrap:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; }

.lity-loader {
  z-index: 9991;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -0.8em;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0;
  transition: opacity 0.3s ease; }
  .lity-loading .lity-loader {
    opacity: 1; }

.lity-container {
  z-index: 9992;
  position: relative;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  white-space: normal;
  max-width: 100%;
  max-height: 100%;
  outline: none !important; }

.lity-content {
  z-index: 9993;
  width: 100%;
  transform: scale(1);
  transition: transform 0.3s ease; }
  .lity-loading .lity-content,
  .lity-closed .lity-content {
    transform: scale(0.8); }
  .lity-content:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }

.lity-close {
  z-index: 9994;
  width: 35px;
  height: 35px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  font-family: Arial, Baskerville, monospace;
  line-height: 35px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border: 0;
  background: none;
  outline: none;
  box-shadow: none; }
  .lity-close::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .lity-close:hover, .lity-close:focus, .lity-close:active, .lity-close:visited {
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial, Baskerville, monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    border: 0;
    background: none;
    outline: none;
    box-shadow: none; }
  .lity-close:active {
    top: 1px; }

/* Image */
.lity-image img {
  max-width: 100%;
  display: block;
  line-height: 0;
  border: 0; }

/* iFrame */
.lity-iframe .lity-container,
.lity-youtube .lity-container,
.lity-vimeo .lity-container,
.lity-facebookvideo .lity-container,
.lity-googlemaps .lity-container {
  width: 100%;
  max-width: 964px; }

.lity-iframe-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: auto;
  pointer-events: auto;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch; }
  .lity-iframe-container iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

.lity-hide {
  display: none; }

.search-wrapper {
  position: relative; }
  .search-wrapper.is-expanded .search-form {
    opacity: 1;
    width: 270px; }
  .search-wrapper.is-expanded .search-toggle-open {
    display: none; }
  .search-wrapper.is-expanded .search-toggle-close {
    display: block; }

@media screen and (max-width: 63.9375em) {
  .search-form {
    position: relative;
    margin: 0.7rem 1rem; } }

@media print, screen and (min-width: 64em) {
  .search-form {
    transition: 0.3s opacity, 0.3s width;
    right: 52px;
    top: 5px;
    opacity: 0;
    position: absolute;
    width: 0;
    overflow: hidden; } }

.search-form input {
  margin-right: 0;
  max-width: none;
  font-size: 0.75rem; }

.search-form [type="submit"] {
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 9px; }
  .search-form [type="submit"]:hover, .search-form [type="submit"]:focus {
    background: none; }
  .search-form [type="submit"] span {
    display: block;
    width: 20px; }
  .search-form [type="submit"] svg {
    width: 100%; }
    .search-form [type="submit"] svg path, .search-form [type="submit"] svg g {
      fill: #29478d; }

.search-toggle-icon {
  width: 25px;
  height: 25px;
  display: inline-block; }
  .search-toggle-icon svg {
    width: 100%;
    height: 100%; }

.search-toggle-close {
  display: none; }

.search-toggle svg path, .search-toggle svg g {
  fill: #fff; }

/* CUSTOM SEARCH STYLING */
.l-content .gsc-result-info {
  font-size: 1rem;
  padding: 0; }
  .l-content .gsc-result-info-container {
    padding: 0; }

.l-content .gsc-control-cse {
  padding: 0;
  font-family: inherit; }
  .l-content .gsc-control-cse .gsc-cursor-box {
    border-top: 2px solid #ccc;
    padding-top: 20px;
    text-align: center;
    font-size: 0.9rem; }
    .l-content .gsc-control-cse .gsc-cursor-box .gsc-cursor-page {
      padding: 4px; }
  .l-content .gsc-control-cse .gsc-url-top {
    font-size: 0.8rem;
    font-family: Helvetica, Arial, Verdana, sans-serif; }
  .l-content .gsc-control-cse .gs-result .gs-title {
    font-size: 1.1rem;
    height: auto;
    line-height: 1.2;
    overflow: visible;
    font-weight: bold;
    text-decoration: none; }
    .l-content .gsc-control-cse .gs-result .gs-title b {
      font-size: inherit;
      color: #444545;
      text-decoration: none; }
    .l-content .gsc-control-cse .gs-result .gs-title:hover, .l-content .gsc-control-cse .gs-result .gs-title:focus {
      text-decoration: underline; }
  .l-content .gsc-control-cse .gsc-table-result {
    font-family: inherit;
    font-size: 125%;
    margin: 0; }
  .l-content .gsc-control-cse tbody {
    border: 0; }

.button, [type="submit"] {
  border-radius: 30px;
  font-weight: bold;
  background: #29478d;
  color: #fff;
  font-size: 0.85rem;
  font-family: "Libre Franklin", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  padding: 0.6rem 1rem;
  text-decoration: none;
  border: 0; }
  .button:hover, .button:focus, [type="submit"]:hover, [type="submit"]:focus {
    text-decoration: none; }

.button.hollow {
  border-width: 2px; }

.button--light {
  background: #fff;
  color: #ab141a; }
  .button--light.hollow {
    border-color: #fff;
    color: #fff; }
    .button--light.hollow:hover, .button--light.hollow:focus {
      background: #fff;
      color: #ab141a;
      border-color: #fff; }
  .button--light:hover, .button--light:focus {
    color: #fff;
    background: #ab141a; }

[type="submit"]:not(.button--light):not(.primary) {
  background: #ab141a; }

.pagination:after {
  content: "";
  border-style: solid;
  border-width: 6px 5px;
  margin-right: -5px;
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
  border-color: transparent transparent transparent #022e92; }

.pagination-pager {
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid #022e92; }
  .pagination-pager:after, .pagination-pager:before {
    display: inline-block;
    border-width: 7px 8px;
    border-style: solid;
    border-color: transparent; }
  .pagination-pager:hover, .pagination-pager:focus {
    border-bottom-color: transparent; }

.pagination-next:after {
  content: "";
  margin-left: 15px;
  margin-right: -8px;
  border-left-color: #022e92; }

.pagination-prev:before {
  content: "";
  margin-right: 15px;
  margin-left: -8px;
  border-right-color: #022e92; }

.date {
  margin-top: 3px;
  color: #ab141a;
  font-weight: bold;
  text-align: center;
  line-height: 1; }
  @media print, screen and (min-width: 40em) {
    .date {
      margin-right: 15px; } }
  .date--horiz {
    margin-right: 0;
    padding-top: 2px;
    font-size: 16px;
    width: 50px; }
  .date-month {
    font-size: 1.2rem; }
  .date-day {
    margin-top: 4px;
    font-size: 1.8rem;
    display: block; }
  .date-year {
    margin-top: 7px;
    display: block; }

.readMore {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
  display: inline-block;
  position: relative;
  text-decoration: none; }
  .readMore:hover, .readMore:focus {
    text-decoration: underline; }
    .readMore:hover:before, .readMore:focus:before {
      transform: translateY(-5px);
      opacity: 0; }
  .readMore--special:before {
    content: "";
    transition: 0.2s all;
    width: 100%;
    position: absolute;
    height: 2px;
    left: 0;
    bottom: -3px;
    background: #022e92; }
  .readMore--special:hover, .readMore--special:focus {
    text-decoration: none; }
  .readMore:after {
    content: "";
    border-style: solid;
    border-width: 6px 5px;
    margin-right: -5px;
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    border-color: transparent transparent transparent #022e92; }

.meta {
  color: #737373;
  font-size: 0.8rem; }
  .meta a {
    color: inherit;
    text-decoration: underline; }
    .meta a:hover, .meta a:focus {
      text-decoration: none; }

.iconLink-icon {
  width: 13px;
  margin-right: 9px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px; }

.iconLink svg {
  width: 100%; }
  .iconLink svg path, .iconLink svg g {
    fill: #022e92; }

.imageLink {
  display: block;
  background: #29478d;
  overflow: hidden; }
  .imageLink img {
    display: block;
    width: 100%;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    transition: 0.3s opacity, 0.3s transform;
    transform: scale(1.006);
    transition-timing-function: ease-in-out; }
  .imageLink:hover img, .imageLink:focus img {
    transform: scale(1.05);
    opacity: 0.7; }

.readMore {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
  display: inline-block;
  position: relative;
  text-decoration: none; }
  .readMore:hover, .readMore:focus {
    text-decoration: underline; }
    .readMore:hover:before, .readMore:focus:before {
      transform: translateY(-5px);
      opacity: 0; }
  .readMore--special:before {
    content: "";
    transition: 0.2s all;
    width: 100%;
    position: absolute;
    height: 2px;
    left: 0;
    bottom: -3px;
    background: #022e92; }
  .readMore--special:hover, .readMore--special:focus {
    text-decoration: none; }
  .readMore:after {
    content: "";
    border-style: solid;
    border-width: 6px 5px;
    margin-right: -5px;
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    border-color: transparent transparent transparent #022e92; }

.status {
  font-weight: bold;
  font-size: 0.7rem;
  text-decoration: none; }
  @media print, screen and (min-width: 40em) {
    .status-label:after {
      content: ":"; } }
  .status:hover, .status:focus {
    text-decoration: underline; }
  .status-icon {
    position: relative;
    width: 30px;
    vertical-align: middle;
    height: 21px;
    margin-right: 3px; }
    .status-icon svg {
      display: block; }
  .status .icon--flag {
    position: absolute;
    left: 0;
    height: 12px;
    width: 22px; }
  .status.is-half .icon--flag, .status.is-half_regional .icon--flag, .status.is-half_statewide .icon--flag {
    top: 10px;
    top: 4px; }
  .status .icon--flag-staff {
    left: 0;
    top: 0;
    position: absolute;
    width: 2px;
    background: #fff;
    height: 100%; }
  .status:hover, .status:focus {
    text-decoration: underline; }
  .status-icon {
    display: inline-block; }

.imageWrapper {
  position: relative; }
  .imageWrapper:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to top, rgba(51, 51, 51, 0.8) 0%, transparent 70%, transparent 100%); }
  .imageWrapper-caption {
    position: absolute;
    z-index: 2;
    color: #fff;
    bottom: 23px;
    left: 31px;
    font-size: 0.8rem; }

@media screen and (max-width: 39.9375em) {
  .jarallax-img {
    margin-top: 0 !important;
    transform: none !important;
    position: relative !important;
    height: 100% !important; } }

@media screen and (max-width: 63.9375em) {
  html.home .l-top {
    background-size: auto 400px; }
    html.home .l-top:after {
      bottom: 200px; } }

@media print, screen and (min-width: 64em) {
  html.home .l-top {
    padding-bottom: 80px; }
  html.home .h1 {
    font-size: 1.5rem; } }

html.home .module {
  border: 0; }
  html.home .module:first-child {
    padding-top: 0; }

a[href*="/"]:after, a[href*="/"]:visited:after {
  content: normal; }

.l-header-logo {
  display: none; }

.l-top--large {
  height: auto !important; }

.section {
  padding: 0.5rem 0 1.5rem; }

.cardCallout:before {
  display: none; }

.modular-item .columns {
  width: 100%; }

.promo-overlay {
  padding: 0; }

.jarallax > div:last-child {
  height: auto !important;
  width: auto !important;
  position: relative !important; }
  .jarallax > div:last-child img {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin: 0 !important;
    height: auto !important;
    width: auto !important;
    position: relative !important;
    transform: none !important; }
