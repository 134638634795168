.cardFeature{
  @include breakpoint(medium down){
    .columns{
      padding: 0;
    }
  }
  @include breakpoint(large){
    .columns{
      &:not(:first-child){
        padding-left: 0;
      }
      &:not(:last-child){
        padding-right: 0;
      }
    }
  }
  &-label{
    font-family: $body-font-family;
    margin-bottom: rem-calc(25);
    color: inherit;
    font-size: rem-calc(22);
    font-weight: bold;
  }
  .readMore{
    font-weight: normal;
    &:before{
      background: #fff;
    }
    &:after{
      border-left-color: #fff;
    }
  }
  &-content{
    padding: 40px;
    background: $primary-color;
    color: #fff;
  }
  &-title{
    color: inherit;
    font-size: rem-calc(24);
    @include breakpoint(large){
      font-size: rem-calc(30);
    }
  }
  a{
    color: inherit;
  }
}


.cardCallout{
  display: block;
  position: relative;
  color: #fff;
  background: $dark-gray;
  overflow: hidden;
  transition: 0.4s padding;
  &-image{
    width: 100%;
    object-fit: cover;
    object-position: center center;
    max-height: 300px;
  }
  &-label{
    font-weight: bold;
    color: inherit;
    position: relative;
    display: block;
    margin-bottom: 10px;
    &-icon{
      &:before{
        vertical-align: middle;
        margin-top: -5px;
        display: inline-block;

      }
      margin-right: 2px;
    }
  }
  &-title{
    color: inherit;
    position: relative;
    text-transform: none;
    font-family: $header-font-family;
    transition: 0.4s padding;
    font-size: rem-calc(24);
  }
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#333333+69,333333+99&0+25,0.65+60 */
    background: -moz-linear-gradient(top, rgba(51,51,51,0) 25%, rgba(51,51,51,0.65) 60%, rgba(51,51,51,0.65) 69%, rgba(51,51,51,0.65) 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(51,51,51,0) 25%,rgba(51,51,51,0.65) 60%,rgba(51,51,51,0.65) 69%,rgba(51,51,51,0.65) 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(51,51,51,0) 25%,rgba(51,51,51,0.65) 60%,rgba(51,51,51,0.65) 69%,rgba(51,51,51,0.65) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00333333', endColorstr='#a6333333',GradientType=0 ); /* IE6-9 */

  }
  &-overlay{
    padding: 25px 30px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    &:after{
      height: 0;
      transition: 0.4s all;
      display: block;
      width: 100%;
      background: #fff;
      content: "";
    }
  }

  &:hover, &:focus{
    .cardCallout-overlay:after{
      height: 2px;
      margin-top: 20px;
    }
    color: #fff;

  }

  &-overlay{
    position: absolute;
    bottom: 0;
  }

}

.card{
  &-title{
    margin-bottom: rem-calc(20);
  }
  padding: 30px 25px;
  @include breakpoint(large){
    padding: 45px 40px;
  }

  &--secondary{
    background: $light-gray;
  }
}
