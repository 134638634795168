.l-utility{
  overflow: hidden;
  position: relative;
  .button{
    border: 3px solid $white;
    font-size: rem-calc(16);
    @include breakpoint(small only){
      font-size: rem-calc(14);
      padding: 11px 16px;
    }
    &:not(.hollow){
      &:hover, &:focus{
        border-color: $secondary-color;
      }
    }
  }
  .l-utility-list{
    @include breakpoint(medium down){
      overflow: hidden;
      > li{
        width: 33.33%;
        margin-left: 0 !important;
        float: left;
        display: block;
        text-align: center;
        &:first-child{
          text-align: left;
        }
        &:last-child{
          text-align: right;
        }
      }
      .status-wrapper{
        padding-top: 10px;
      }
      display: block;
    }
  }

  @include breakpoint(large){
    margin-bottom: -50px;
  }
}
.l-top{

  &-social{
    width: 39%;
    margin-top: 35px;
    padding-left:  12px;
    @include breakpoint(medium){
      margin-top: 30px;
    }
  }
  background-repeat: no-repeat;
  background-size: auto 500px;
  background-color: $dark-gray;
  background-position: 40% bottom;
  @include breakpoint(small only){
    background-size: auto 300px;
    .iconSocial{
      width: 30px;
      padding: 7px 0px;
      height: 30px;
    }
  }
  @include breakpoint(large){
    background-position: center top;
    background-size: cover;

  }
  &-alert{
    padding: 10px;
    .readMore{
      font-family: $body-font-family;
      font-weight: normal;
      color: inherit;
      &:after{
        border-left-color: #fff;
      }
    }
  }
  padding: 17px 0;
  position: relative;
  color: #fff;
  .hero{
    margin-top: 100px;
  }
  .readMore{
    &:before{
      background: #fff;
    }
    &:after{
      border-left-color: #fff;
    }
  }
  > *{
    z-index: 3;
    position: relative;
  }
  &:after{
      left: 0;
      width: 100%;
      height: 200px;

      @include breakpoint(medium down){
        z-index: 1;
        content: "";
        background: linear-gradient(to bottom, $dark-gray 0%, transparent 70%, transparent 100%);
        position: absolute;
        bottom: 100px;
      }
      @include breakpoint(medium only){
        bottom: 300px;
      }

  }
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:  rgb(3, 47, 146);
    opacity: 0.7;
    z-index: 2;
  }

  &--large{
    &--gray{
      &:before{
        background-color: scale-color($black, $lightness: 10%);

      }
    }
    @include breakpoint(medium){
      height: 500px;
      &:before{
        background-color: transparent;
        opacity: 0.8;
        @include linear-gradient(180deg, scale-color($primary-color, $lightness: 10%) 35%, transparent);
      }
      &--gray{
        .iconSocial--light svg {
          g, path{
            fill: scale-color($black, $lightness: 10%);
          }
        }
        &:before{
          @include linear-gradient(180deg, scale-color($black, $lightness: 10%) 35%, transparent);

        }
      }

    }
    @include breakpoint(xlarge){
      height: 600px;
    }
  }
  a{
    &:not(.button){
      color: #fff;
    }
  }
}

.l-breadcrumbs{
  position: relative;
}

.l-header{
  position: relative;
  &-title{
    font-size: rem-calc(16);
    padding: 0 15px;

    @include breakpoint(large){
      padding: 0;
      margin-top: 50px;
      font-size: rem-calc(24);
      margin-left: 20px;
      display: block;
    }
    @include breakpoint(xlarge){
      margin-left: 30px;
      margin-bottom: 20px;
    }
    font-family: $header-font-family;
    font-weight: bold;
    a{
      text-decoration: none;
      &:hover, &:focus{
        text-decoration: none;
      }
    }

  }
  &-logo{
    display: inline-block;
    float: left;
    margin-right: 10px;
    margin-bottom: 15px;
    text-align: center;
    @media all and (min-width: 1024px) and (max-width: 1100px){
      width: 8%;
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
  @include breakpoint(medium down){

    &-logo{
      width: 26%;
      img{
      max-width: 100px;
      }
    }
  }
  @include breakpoint(xlarge){
    &-nav{
      margin-left: 10px;
    }
  }

}

.l-footer{
  background: #333;
  color: #fff;
  padding-top: 10px;
  .iconSocial{
    @include breakpoint(small only){
      margin-bottom: rem-calc(10);
    }
  }
  @include breakpoint(medium down){
    .menu{
      > li{
        > a{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  &-contact{
    margin: 0 0 20px;
    @include breakpoint(small only){
      margin-top: rem-calc(15);
    }
  }
  @include breakpoint(medium){
    &-contact{
      margin: 5px 0 0;
    }
  }
  hr{
    border-color: #666;
  }
  &-links{
    font-size: rem-calc(18);
    li{
      margin-bottom: 5px;
    }

  }
  &-address{
    font-size: rem-calc(18);
    line-height: 1.3;
  }
  &-title{
    font-size: rem-calc(20);
    color: inherit;
    margin-bottom: rem-calc(5);
  }
  &-logo{
    max-width: 100px;
    display: inline-block;
    img{
      max-width: 100%;
    }
  }
  a{
    &:not(.button){
      text-decoration: none;
      color: inherit;
      &:hover, &:focus{
        text-decoration: underline;
      }
    }
  }
  .iconSocial{
    svg{
      path, g{
        fill: #333;
      }
    }
  }
}

.l-sidebar{
  @include breakpoint(small only){
    .module{
      &:first-child{
        margin-top: 30px;
        border-top: 2px solid $medium-gray;
        padding-top: 30px;
      }
    }
  }
}

.l-siteContent{
  padding: rem-calc(30) 0 0;
  > *:last-child{
    margin-bottom: rem-calc(60);
  }
  @include breakpoint(large){
    min-height: 40vh;
  }
  @media screen and (min-width: map-get($breakpoints,medium)) and (max-width: map-get($breakpoints,xlarge)){
    .row{
      .columns{
        &:first-child{
          padding-left: rem-calc(25);
        }
        &:last-child{
          padding-right: rem-calc(25);
        }
      }
    }
  }
}
.l-sidebar{
  @include breakpoint(small only){
    .module{
      &:first-child{
        border-top: 2px solid $medium-gray;
        padding-top: rem-calc(20);
        margin-top: rem-calc(20);
      }
    }
  }
}
