.module{
  padding-top: 30px;
  &-header{
    margin-bottom: rem-calc(10);
  }
  &:first-child{
    padding-top: 0;
  }
  &:not(:last-child){
    border-bottom: 2px solid $medium-gray;
    padding-bottom: 30px;
  }
  > *:last-child{
    margin-bottom: 0;
    .button{
      margin-bottom: 0;
    }
  }
}
