.search{
  &-wrapper{
    position: relative;
    &.is-expanded{
      .search-form{
        opacity: 1;
        width: 270px;
      }
      .search-toggle-open{
        display: none;
      }
      .search-toggle-close{
        display: block;
      }
    }
  }
  &-form{
    @include breakpoint(medium down){
      position: relative;
      margin: 0.7rem 1rem;
    }
    @include breakpoint(large){
      transition: 0.3s opacity, 0.3s width;
      right: 52px;
      top: 5px;
      opacity: 0;
      position: absolute;
      width: 0;
      overflow: hidden;
    }
    input{
      margin-right: 0;
      max-width: none;
      font-size: rem-calc(15)
    }
    [type="submit"]{
      background: none;
      position: absolute;
      top: 0;
      right: 0;
      padding: 9px;
      &:hover, &:focus{
        background: none;
      }
      span{
        display: block;
        width: 20px;
      }
      svg{
        width: 100%;
        path, g{
          fill: $primary-color;
        }
      }
    }
  }
  &-toggle{
    &-icon{
      width: 25px;
      height: 25px;
      display: inline-block;
      svg{
        width: 100%;
        height: 100%;
      }
    }
    &-close{
      display: none;
    }

    svg{
      path, g{
        fill: #fff;
      }
    }
  }
}


/* CUSTOM SEARCH STYLING */

.l-content{
  .gsc-result-info{
    font-size: rem-calc(20);
    padding: 0;
    &-container{
      padding: 0;
    }
  }

  .gsc-control-cse{
    padding: 0;
    .gsc-cursor-box{
      border-top: 2px solid $medium-gray;
      padding-top: 20px;
      text-align: center;
      font-size: rem-calc(18);
      .gsc-cursor-page{
        padding: 4px;
      }
    }
    font-family: inherit;
    .gsc-url-top{
      font-size: rem-calc(16);
      font-family: Helvetica, Arial, Verdana, sans-serif;
    }
    .gs-result {
      .gs-title{
        font-size: rem-calc(22);
        height: auto;
        line-height: 1.2;
        overflow: visible;
        font-weight: bold;
        text-decoration: none;
        b{
          font-size: inherit;
          color: $body-font-color;
          text-decoration: none;
        }
        &:hover, &:focus{
          text-decoration: underline;
        }
      }
    }
    .gsc-table-result{
      font-family: inherit;
      font-size: $global-font-size;
      margin: 0;
    }
    tbody{
      border: 0;
    }
  }
}
