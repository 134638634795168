@import "helpers/spacing";



.sr-only{
  position:absolute !important;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.flex-container{
  display: flex;
}

.objectFit-cover{
  object-fit: cover;
}

img{
  height: auto !important;
}

.img{
  &--left{

    float: left;
    margin: 0 30px 30px 0;
    max-width: 40%;

  }
  &--right{

    float: right;
    margin: 0 30px 30px;
    max-width: 40%;

  }
}

.clear{
  clear: both;
}


.font-small{
  font-size: rem-calc(14);
}

@mixin linear-gradient($args...) {
 background-image: -o-linear-gradient($args);
 background-image: -moz-linear-gradient($args);
 background-image: -webkit-linear-gradient($args);
 background-image: linear-gradient($args);
}

.textTransform-none{
  text-transform: none;
}
