.icon{
  &#{Social}{

    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 100%;
    padding: 9px;
    background: $primary-color;
    svg{
      height: 100%;
      width: 100%;
      path, g{
        fill: #fff;
      }
    }
    &:hover, &:focus{
      background: $button-background-hover;
    }
    &--light{
      background: #fff;
      color: $body-font-color;
      &:hover, &:focus{
        background: $medium-gray;
      }
      svg{
        path, g{
          fill: $primary-color;
        }
      }

    }
  }
  &--play{
    &:before{
      content: "";
      border-style: solid;
      border-color: transparent transparent transparent #fff;
      border-width: 10px 10px;
      display: inline-block;
    }
  }
}
