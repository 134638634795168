.calloutImage{
  display: block;
  position: relative;
  overflow: hidden;
  background: $black;
  &-image{
    margin-bottom: 0;
    transition: 0.3s transform, 0.3s opacity;
    transform: scale(1.006);
    width: 100%;
  }
  &-overlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 25px 30px;
    background: linear-gradient(to top, rgba($black,0.8) 0%, transparent 70%, transparent 100%);
    @include breakpoint(large){
      padding: 40px 50px;
    }
    > *:last-child{
      margin-bottom: 0;
    }
  }
  &-header{
    color: $white;
    line-height: 1.3;
    max-width: 300px;
    text-transform: none;
    @include breakpoint(large){
      font-size: rem-calc(24);
    }
    &.readMore{
      &:after{
        border-left-color: $white;
      }
    }
  }
  &:hover, &:focus{
    .readMore{
      text-decoration: none;
    }
    .calloutImage-image{
      opacity: 0.7;
      transform: scale(1.1);
    }
  }
}
