.breadcrumbs {
  a{
    text-decoration: underline;
    &:hover, &:focus{
      text-decoration: none;
    }
  }
  li{
    &:last-child{
      a{
        color: inherit;
        text-decoration: none;
      }
    }
    &:not(:last-child){
      &:after{
        content: "";
        border-style: solid;
        height: 0;
        border-width: 5px 4px;
        width: 0;
        display: inline-block;
        vertical-align: middle;
        margin: 0 6px 0px 10px;
        top: 0;
        border-color: transparent transparent transparent #fff;
      }
    }
  }

}
