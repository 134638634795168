.list{
  &--primary{
    font-size: rem-calc(18);
    font-family: $header-font-family;
    font-weight: bold;
    > li{
      margin-bottom: rem-calc(8);
    }
    a{
      text-decoration: none;
      &:hover, &:focus{
        text-decoration: underline;
      }
    }
  }
  &--subnav{
    &-parent{
      &.selected{
        > a{
          color: $dark-gray;
        }
      }
      a{
        font-family: $header-font-family;
        text-transform: none;
        text-decoration: none;
        &:hover, &:focus{
          text-decoration: underline;
        }
      }
    }
    font-family: $header-font-family;
    font-weight: bold;
    margin-top: rem-calc(10);
    margin-left: 0;
    font-size: rem-calc(16);
    li{
      // &.parent-selected, &.selected{
      //   > ul{
      //     display: block;
      //   }
      // }
      &.selected{
        > a{
          color: $dark-gray;
        }
      }
    }
    a{
      text-decoration: none;
      &:hover, &:focus{
        text-decoration: underline;
      }
    }
    > li{
      margin-bottom: rem-calc(8);
      ul{
        //display: none;
        margin-top: rem-calc(10);
        font-size: rem-calc(14);
        margin-left: rem-calc(25);
        margin-bottom: rem-calc(10);
        li{
          margin-bottom: rem-calc(10);
        }
      }
    }

  }
  &--secondary{
    font-size: rem-calc(16);
    padding-left: 40px;
    color: #777;
    .columns{
      padding: 0;
      padding-right: 30px;
      margin-bottom: 10px;
    }
  }
  &--inline{
    display: inline-block;
    list-style-type: none;
    margin-left: 0;
    > li{
      vertical-align: middle;
      display: inline-block;
      &:not(:first-child){
        margin-left: 10px;
      }
    }
  }
  &--unstyled{
    list-style-type: none;
    margin-left: 0;
    ul{
      list-style-type: none;
    }
  }
}
