.modular{

  margin-top: rem-calc(30);

  &-item{
    &--wysiwyg{
      p{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}
