@charset 'utf-8';

// === Foundation Imports ===
$header-font-family: Georgia, Times, 'Times New Roman', serif;
@import 'settings';
@import 'foundation';


// === Components ===

@include foundation-global-styles;
@include foundation-grid;
//@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
// @include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
// @include foundation-off-canvas;
@include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;


// === Custom Imports ===

@import "elements";
@import "helper";
@import "layout";
@import "components";
@import "forms";
@import "pages/home";

a[href*="/"]{
  &:after, &:visited:after{
    content: normal;
  }
}

.l-header-logo{
  display: none;
}

.l-top--large {
    height: auto !important;
}

.section{
  padding: rem-calc(10) 0 rem-calc(30);
}

.cardCallout{
  &:before{
    display: none;
  }
}

.modular{
  &-item{
    .columns{
      width: 100%;
    }
  }
}

.promo{
  &-overlay{
    padding: 0;
  }
}

.jarallax{
  > div:last-child{
    height: auto !important;
    width: auto !important;
    position: relative !important;
    img{
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      margin: 0 !important;
      height: auto !important;
      width: auto !important;
      position: relative !important;
      transform: none !important;
    }
  }

}
