html.home{
  @include breakpoint(medium down){
    .l-top{
      background-size: auto 400px;
      &:after{
        bottom: 200px;
      }
    }
  }
  @include breakpoint(large){
    .l-top{
      padding-bottom: 80px;
    }
    .h1{
      font-size: rem-calc(30);
    }
  }
  .module{
    border: 0;
    &:first-child{
      padding-top: 0;
    }
  }
}
