.button, [type="submit"]{
  border-radius: 30px;
  font-weight: bold;
  background: $button-background;
  color: $button-color;
  font-size: rem-calc(17);
  font-family: $body-font-family;
  padding: $button-padding;
  text-decoration: none;
  border: 0;
  &:hover, &:focus{
    text-decoration: none;
  }
}
.button{
  &.hollow{
    border-width: 2px;
  }
  &--light{
    &.hollow{
      border-color: $white;
      color: $white;
      &:hover, &:focus{
        background: $white;
        color: $secondary-color;
        border-color: $white;
      }
    }
    background: #fff;
    color: $secondary-color;
    &:hover, &:focus{
      color: #fff;
      background: $secondary-color;
    }
  }
}

[type="submit"]{
  &:not(.button--light):not(.primary){
    background: $secondary-color;
  }
}
