.pagination{
  &:after{
    content: "";
    border-style: solid;
    border-width: 6px 5px;
    margin-right: -5px;
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    border-color: transparent transparent transparent $anchor-color;
  }
  &-pager{
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 2px solid $anchor-color;
    &:after, &:before{
      display: inline-block;
      border-width: 7px 8px;
      border-style: solid;
      border-color: transparent;
    }
    &:hover, &:focus{
      border-bottom-color: transparent;
    }
  }
  &-next{
    &:after{
      content: "";
      margin-left: 15px;
      margin-right: -8px;
      border-left-color: $anchor-color;
    }
  }
  &-prev{
    &:before{
      content: "";
      margin-right: 15px;
      margin-left: -8px;
      border-right-color: $anchor-color;
    }
  }
}
