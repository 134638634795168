
@include breakpoint(small only){
  body{
    font-size: rem-calc(16);
  }
}


address{
  font-style: normal;
}

h2, h3, h4{
  a{
    text-decoration: none;
    &:hover, &:focus{
      text-decoration: underline;
    }
  }
}
.h1, .h2{
  font-size: rem-calc(18);
  text-transform: none;
  color: $black;
  line-height: $header-lineheight;
  font-family: $header-font-family;
  @include breakpoint(medium){
    font-size: rem-calc(24);
  }
}

h3, .h3{
  font-family: $body-font-family;
  color: $dark-gray;
  font-size: rem-calc(22);
  font-weight: bold;
}

.h5{
  font-size: rem-calc(18);
}

blockquote{
  border: 0;
  position: relative;
  padding-left: 40px;
  margin-bottom: rem-calc(30);
  display: inline-block;
  p{
    font-family: $header-font-family;
    color: $body-font-color;
    font-weight: bold;
    &:last-child{
      margin-bottom: 0;
      &:after{
        font-weight: normal;
        right: 0;
        bottom: 25px;
        content: "\201d";
      }
    }
  }
  &:before{
    left: 0;
    top: -10px;
    content: "\201c";
  }

  &:before, p:last-child:after{
    position: absolute;
    height: 0;
    color: $secondary-color;
    font-size: rem-calc(70);
    font-family: $header-font-family;
  }
  p.blockquote-attribution{
    font-family: $body-font-family;
    font-weight: normal;
    margin-bottom: 0;
    position: relative;
    &:before{
      content: "\2014";
      margin-right: 3px;
    }
    &:last-child{
      &:after{
        top: -45px;
      }

    }

  }
}

table{
  font-size: rem-calc(18);
}

ul, ol{
  &:not([class]){
    li{
      margin-bottom: rem-calc(10);
      ul, ol{
        margin-top: rem-calc(10);
      }
    }
  }
}
