$spacer:   0.25rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  4: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  ),
  5: (
    x: ($spacer-x * 5),
    y: ($spacer-y * 5)
  )
) !default;

// Width
.h-100 { height: 100% !important; }

.w-100 { width: 100% !important; }

// Margin and Padding

.m-x-auto {
  margin-right: auto !important;
  margin-left:  auto !important;
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);

    .#{$abbrev}-a-#{$size} {
      #{$prop}:        $length-y $length-x !important;
      @include breakpoint(large up){
        #{$prop}:  $length-y*2 $length-x*2  !important;
      }
    } // a = All sides
    .#{$abbrev}-t-#{$size} {
      #{$prop}-top: $length-y !important;
      @include breakpoint(large up){
        #{$prop}-top:  $length-y*2 !important;
      }
    }
    .#{$abbrev}-r-#{$size} {
      #{$prop}-right:  $length-x !important;
      @include breakpoint(medium up){
        #{$prop}-right:  $length-x*2 !important;
      }
    }

    @include breakpoint(small only){
      .small-#{$abbrev}-b-#{$size} {
        #{$prop}-bottom: $length-y*2 !important;
      }

    }

    .#{$abbrev}-b-#{$size} {
      #{$prop}-bottom: $length-y !important;
      @include breakpoint(medium up){
        #{$prop}-bottom:  $length-y*2 !important;
      }
    }
    .#{$abbrev}-l-#{$size} {
      #{$prop}-left:   $length-x !important;
      @include breakpoint(medium up){
        #{$prop}-left:  $length-x*2  !important;
      }
     }

    // Axes
    .#{$abbrev}-x-#{$size} {
      #{$prop}-right:  $length-x !important;
      #{$prop}-left:   $length-x !important;
      @include breakpoint(large){
        #{$prop}-right:  $length-x*2 !important;
        #{$prop}-left:   $length-x*2 !important;
      }
    }
    .#{$abbrev}-y-#{$size} {
      #{$prop}-top:    $length-y !important;
      #{$prop}-bottom: $length-y !important;
      @include breakpoint(large){
        #{$prop}-top:  $length-y*2 !important;
        #{$prop}-bottom:   $length-y*2 !important;
      }
    }
  }
}

// Positioning

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 30;
}
