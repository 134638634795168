.section{
  padding: rem-calc(30) 0;
  @include breakpoint(large){
    padding: rem-calc(60) 0 ;
  }

  &-label{
    font-size: rem-calc(30);
    margin-bottom: rem-calc(25);

  }
  &--highlight{
    background: $primary-color;
    color: $white;
    padding: rem-calc(50) 0;
    .section-header{
      color: inherit;
    }
  }
  &--secondary{
    p:last-child{
      margin-bottom: 0;
    }
    &:last-child{
      margin-top: rem-calc(30);
    }
  }
}
