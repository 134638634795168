@import
  "components/section",
  "components/card",
  "components/hero",
  "components/nav",
  "components/callout",
  "components/promo",
  "components/modular",
  "components/shared";
.readMore{
  font-family: $header-font-family;
  font-weight: bold;
  display: inline-block;
  position: relative;
  text-decoration: none;
  &:hover, &:focus{
    text-decoration: underline;
    &:before{
      transform: translateY(-5px);
      opacity: 0;
    }
  }
  &--special{
    &:before{
      content: "";
      transition: 0.2s all;
      width: 100%;
      position: absolute;
      height: 2px;
      left: 0;
      bottom: -3px;
      background: $anchor-color;
    }
    &:hover, &:focus{
      text-decoration: none;
    }
  }
  &:after{
    content: "";
    border-style: solid;
    border-width: 6px 5px;
    margin-right: -5px;
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    border-color: transparent transparent transparent $anchor-color;
  }
}


.status{
  font-weight: bold;
  font-size: rem-calc(14px);
  text-decoration: none;
  &-label{
    @include breakpoint(medium){
      &:after{
        content: ":";
      }
    }
  }
  &:hover, &:focus{
    text-decoration: underline;
  }
  &-icon{
    position: relative;
    width: 30px;
    vertical-align: middle;
    height: 21px;
    margin-right: 3px;
    svg{
      display: block;
    }
  }
  .icon--flag{
    position: absolute;
    left: 0;
    height: 12px;
    width: 22px;
  }
  &.is-half, &.is-half_regional, &.is-half_statewide{
    .icon--flag{
      top: 10px;
      top: 4px;
    }
  }
  .icon--flag-staff{
    left: 0;
    top: 0;
    position: absolute;
    width: 2px;
    background: #fff;
    height: 100%;
  }
  &:hover, &:focus{
    text-decoration: underline;
  }
  &-icon{
    display: inline-block;
  }
}

.imageWrapper{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to top, rgba($black,0.8) 0%, transparent 70%, transparent 100%);
  }
  &-caption{
    position: absolute;
    z-index: 2;
    color: $white;
    bottom: 23px;
    left: 31px;
    font-size: rem-calc(16);
  }
}

.jarallax{
  &-img{
    @include breakpoint(small only){
      margin-top: 0 !important;
      transform: none !important;
      position: relative !important;
      height: 100% !important;
    }
  }
}
