@import "shared/lists";
@import "shared/icons";
@import "shared/card";
@import "shared/modules";
@import "shared/breadcrumbs";
@import "shared/lity";
@import "shared/search";
@import "shared/buttons";
@import "shared/pagination";
.date{
  margin-top: 3px;
  color: $secondary-color;
  font-weight: bold;
  text-align: center;
  line-height: 1;

  @include breakpoint(medium){
    margin-right: 15px;
  }
  &--horiz{
    margin-right: 0;
    padding-top: 2px;
    font-size: 16px;
    width: 50px;
  }
  &-month{
    font-size: rem-calc(24);
  }
  &-day{
    margin-top: 4px;
    font-size: rem-calc(36);
    display: block;
  }
  &-year{
    margin-top: 7px;
    display: block;
  }
}

.readMore{
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  display: inline-block;
  position: relative;
  text-decoration: none;

  &:hover, &:focus{
    text-decoration: underline;
    &:before{
      transform: translateY(-5px);
      opacity: 0;
    }
  }
  &--special{
    &:before{
      content: "";
      transition: 0.2s all;
      width: 100%;
      position: absolute;
      height: 2px;
      left: 0;
      bottom: -3px;
      background: $anchor-color;
    }
    &:hover, &:focus{
      text-decoration: none;
    }
  }
  &:after{
    content: "";
    border-style: solid;
    border-width: 6px 5px;
    margin-right: -5px;
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    border-color: transparent transparent transparent $anchor-color;
  }
}

.meta{
  color: $dark-gray;
  font-size: rem-calc(16);
  a{
    color: inherit;
    text-decoration: underline;
    &:hover, &:focus{
      text-decoration: none;
    }
  }
}

.iconLink{
  &-icon{
    width: 13px;
    margin-right: 9px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -5px;
  }
  svg{
    width: 100%;
    path, g{
      fill: $anchor-color;
    }
  }
}


.imageLink{
  display: block;
  background: $primary-color;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    opacity: 1;
    -webkit-backface-visibility: hidden;
    transition: 0.3s opacity, 0.3s transform;
    transform: scale(1.006);
    transition-timing-function: ease-in-out;
  }
  &:hover, &:focus{
    img{
      transform: scale(1.05);
      opacity: 0.7;
    }
  }
}
