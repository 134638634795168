.promo{
  position: relative;
  overflow: hidden;
  z-index: 0;
   .jarallax-img {
      position: absolute;
      object-fit: cover;
      /* support for plugin https://github.com/bfred-it/object-fit-images */
      font-family: 'object-fit: cover;';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
  }
  &-overlay{
    padding: rem-calc(40) rem-calc(30);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @include linear-gradient(to bottom, rgba($black,0.8) 0%,rgba($black,0.2) 50%,rgba($black,0.8) 100%);
    position: relative;
  }
  &-button{
    margin-bottom: 0;
  }

  &-header{
    color: $white;
  }
  @include breakpoint(medium){
    &-overlay{
      padding: rem-calc(60) rem-calc(30);
    }
  }
  @include breakpoint(large){
    &-header{
      font-size: rem-calc(36);
      line-height: 1.2;
      margin-bottom: rem-calc(25);
    }
    &-overlay{
      padding: rem-calc(90) rem-calc(30);
    }
  }
  @include breakpoint(xxlarge){
    &-overlay{
      padding: rem-calc(150) 0;
    }
  }
}
