.hero{
  position: relative;
  @include breakpoint(medium down){
    &-header, &-subheader{
      margin-bottom: 15px;
      width: 100%;
      a{
        display: block;
        padding-bottom: 5px;
        width: 100%;

      }
    }
  }
  @include breakpoint(large){
    &-columns{
      min-height: 30vh;
    }
    .readMore--special{
      &:before{
        height: 3px;
      }
    }
    .readMore{
      padding-bottom: rem-calc(20);

    }
    &-header{
      font-size: rem-calc(56);
      margin-right: 30px;
      .readMore--special:after{
        border-width: 14px 14px;
        margin-left: 20px;
      }
    }
    &-subheader{
      font-size: rem-calc(28);
      &:not(:last-child){
        margin-bottom: rem-calc(60);
      }
      .readMore--special:after{
        border-width: 9px 8px;
        margin-left: 10px;
      }
    }
    &-sidebar{
      padding-top: 50px;
    }
  }
}
