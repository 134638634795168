#main-menu{
  > ul{
    > li{
      &.is-selected{
        > a{
          text-decoration: underline;
          @include breakpoint(large){
            text-decoration: none;
            &:before{
              opacity: 1;
              transform: translateY(0px);
            }
          }

        }
      }
      > a{
        font-weight: bold;
        text-decoration: none;
        position: relative;
        &:before{
          position: absolute;
          bottom: 5px;
          left: 20px;
          background: #fff;
          height: 2px;
          width: calc(100% - 40px);
          content: "";
          opacity: 0;
          transition: 0.2s all;
          transform: translateY(-10px);
        }
        &:hover, &:focus{
          @include breakpoint(large){
            text-decoration: none;
            &:before{
              transform: translateY(0px);
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .submenu{
    margin-bottom: rem-calc(15);
    a{
      font-size: rem-calc(18);
      text-decoration: none;
      &:hover, &:focus{
        text-decoration: underline;
      }
    }
  }
}

.top-bar{
  .title-bar{
    background: transparent;
    .menu-icon{
      margin-top: 25px;
    }
  }
}
